import Stack from '@mui/material/Stack';

import { BackToTop } from '../../../components/animate/back-to-top/BackToTop';
import { ScrollProgress } from '../../../components/animate/scroll-progress/ScrollProgress';
import { useScrollProgress } from '../../../components/animate/scroll-progress/use-scroll-progress';

export type HomeViewProps = {
    hero?: React.ReactNode;
    children: React.ReactNode;
};

/**
 * HomeView component renders the main view of the home section.
 * It includes a scroll progress indicator, a back-to-top button,
 * a hero section, and children components.
 *
 * @param {HomeViewProps} props - The properties for the HomeView component.
 * @param {React.ReactNode} props.hero - The hero section to be displayed at the top.
 * @param {React.ReactNode} props.children - The children components to be rendered within the view.
 *
 * @returns {JSX.Element} The rendered HomeView component.
 */
export function HomeView({ hero, children }: HomeViewProps) {
    const pageProgress = useScrollProgress();

    return (
        <>
            <ScrollProgress
                variant="linear"
                progress={pageProgress.scrollYProgress}
                sx={{ position: 'fixed' }}
            />

            <BackToTop />

            {hero}
            <Stack sx={{ position: 'relative', bgcolor: 'background.default' }}>{children}</Stack>
        </>
    );
}
