import type { UserContextType } from '@mej/ssr';
import { Outlet, createRootRouteWithContext } from '@tanstack/react-router';
import React from 'react';

const TanStackRouterDevtools = import.meta.env.PROD
    ? () => null // Render nothing in production
    : React.lazy(() =>
          // Lazy load in development
          import('@tanstack/router-devtools').then((res) => ({
              default: res.TanStackRouterDevtools,
              // For Embedded Mode
              // default: res.TanStackRouterDevtoolsPanel
          })),
      );

/**
 * @deprecated WIP
 */
function RootComponent() {
    return (
        <>
            <Outlet />
            <TanStackRouterDevtools />
        </>
    );
}

export const Route = createRootRouteWithContext<Partial<UserContextType>>()({
    component: RootComponent,
});
