import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';

import { layoutClasses } from '../classes';

/**
 * Main layout component that wraps its children with a Box component.
 *
 * @param {BoxProps} props - The properties passed to the component.
 * @param {React.ReactNode} props.children - The child elements to be rendered inside the main layout.
 * @param {object} [props.sx] - The style object to customize the Box component.
 * @param {object} [props.other] - Any other properties to be passed to the Box component.
 *
 * @returns {JSX.Element} The rendered main layout component.
 */
export function Main({ children, sx, ...other }: BoxProps) {
    return (
        <Box
            component="main"
            className={layoutClasses.main}
            sx={{
                display: 'flex',
                flex: '1 1 auto',
                flexDirection: 'column',
                ...sx,
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...other}
        >
            {children}
        </Box>
    );
}
