/**
 * Checks if the given URL contains query parameters.
 *
 * @param url - The URL to check for query parameters.
 * @returns `true` if the URL contains query parameters, otherwise `false`.
 */
export const hasParams = (url: string): boolean => {
    // eslint-disable-next-line prefer-destructuring
    const queryString = url.split('?')[1];
    return queryString ? new URLSearchParams(queryString).toString().length > 0 : false;
};

/**
 * Remove last slash
 * [1]
 * @input  = '/dashboard/calendar/'
 * @output = '/dashboard/calendar'
 * [2]
 * @input  = '/dashboard/calendar'
 * @output = '/dashboard/calendar'
 */
export function removeLastSlash(pathname: string): string {
    if (pathname !== '/' && pathname.endsWith('/')) return pathname.slice(0, -1);
    return pathname;
}

/**
 * Removes query parameters from a given URL string.
 *
 * @param url - The URL string from which to remove query parameters.
 * @returns The URL string without query parameters.
 *
 * @throws Will return the original URL string if an error occurs during URL parsing.
 */
export function removeParams(url: string): string {
    try {
        const urlObj = new URL(url, window.location.origin);

        return removeLastSlash(urlObj.pathname);
    } catch (error) {
        return url;
    }
}

/**
 * Checks if a given URL is an external link.
 * External links start with 'http'.
 *
 * @param url - The URL to check.
 * @returns `true` if the URL starts with 'http', indicating it is an external link; otherwise, `false`.
 */
export function isExternalLink(url: string): boolean {
    return url.startsWith('http');
}
