import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import ListSubheader from '@mui/material/ListSubheader';
import Portal from '@mui/material/Portal';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { useCallback, useEffect, useRef, useState } from 'react';

import { NavLi, NavUl } from '../../../../components/nav-section';
import { useActiveLink } from '../../../../routes/hooks/use-active-link';
import { usePathname } from '../../../../routes/hooks/use-pathname';
import { isExternalLink, removeLastSlash } from '../../../../routes/utils';
import { paper } from '../../../../theme/styles/mixins';
import type { NavListProps, NavSubListProps } from '../types';
import { NavItem, NavItemDashboard } from './nav-desktop-item';

/**
 * Component that renders a navigation list item. It handles the display of a navigation item
 * and its submenu if it has children. The submenu is displayed in a portal with a fade-in effect.
 */
export function NavList({ data }: NavListProps) {
    const theme = useTheme();

    const navItemRef = useRef<HTMLButtonElement | null>(null);

    const pathname = usePathname();

    const [openMenu, setOpenMenu] = useState(false);

    const active = useActiveLink(data.path, !!data.children);

    const [clientRect, setClientRect] = useState<Record<string, number>>({ top: 0, height: 0 });

    const handleOpenMenu = useCallback(() => {
        if (data.children) {
            setOpenMenu(true);
        }
    }, [data.children]);

    const handleCloseMenu = useCallback(() => {
        setOpenMenu(false);
    }, []);

    useEffect(() => {
        if (openMenu) {
            handleCloseMenu();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname]);

    const renderNavItem = (
        <NavItem
            ref={navItemRef}
            // slots
            title={data.title}
            path={data.path}
            // state
            active={active}
            hasChild={!!data.children}
            open={data.children && !!openMenu}
            externalLink={isExternalLink(data.path)}
            // action
            onMouseEnter={handleOpenMenu}
            onMouseLeave={handleCloseMenu}
        />
    );

    const handleGetClientRect = useCallback(() => {
        const element = navItemRef.current;

        if (element) {
            const rect = element.getBoundingClientRect();
            setClientRect({ top: rect.top, height: rect.height });
        }
    }, []);

    useEffect(() => {
        handleGetClientRect();

        window.addEventListener('scroll', handleGetClientRect);

        return () => {
            window.removeEventListener('scroll', handleGetClientRect);
        };
    }, [handleGetClientRect]);

    if (data.children) {
        return (
            <NavLi sx={{ height: 1 }}>
                {renderNavItem}

                {openMenu && (
                    <Portal>
                        <Fade in>
                            <Box
                                onMouseEnter={handleOpenMenu}
                                onMouseLeave={handleCloseMenu}
                                sx={{
                                    pt: 0.5,
                                    left: 0,
                                    right: 0,
                                    mx: 'auto',
                                    position: 'fixed',
                                    zIndex: theme.zIndex.modal,
                                    maxWidth: theme.breakpoints.values.lg,
                                    top: Math.round(clientRect.top + clientRect.height),
                                }}
                            >
                                <Box
                                    component="nav"
                                    sx={{
                                        ...paper({ theme, dropdown: true }),
                                        borderRadius: 2,
                                        p: theme.spacing(5, 1, 1, 4),
                                    }}
                                >
                                    <NavUl
                                        sx={{
                                            gap: 3,
                                            width: 1,
                                            flexWrap: 'wrap',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        {data.children.map((list) => (
                                            // eslint-disable-next-line @typescript-eslint/no-use-before-define
                                            <NavSubList
                                                key={list.subheader}
                                                subheader={list.subheader}
                                                data={list.items}
                                            />
                                        ))}
                                    </NavUl>
                                </Box>
                            </Box>
                        </Fade>
                    </Portal>
                )}
            </NavLi>
        );
    }

    return <NavLi sx={{ height: 1 }}>{renderNavItem}</NavLi>;
}

/**
 * Component that renders a navigation sublist with optional dashboard-specific styling.
 */
function NavSubList({ data, subheader, sx, ...other }: NavSubListProps) {
    const pathname = usePathname();

    const isDashboard = subheader === 'Dashboard';

    return (
        <Stack
            component={NavLi}
            alignItems="flex-start"
            sx={{
                flex: '1 1 auto',
                ...(isDashboard && { maxWidth: { md: 1 / 3, lg: 540 } }),
                ...sx,
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...other}
        >
            <NavUl>
                <ListSubheader
                    disableSticky
                    disableGutters
                    sx={{ fontSize: 11, color: 'text.primary', typography: 'overline' }}
                >
                    {subheader}
                </ListSubheader>

                {data.map((item) =>
                    isDashboard ? (
                        <NavLi key={item.title} sx={{ mt: 1.5 }}>
                            <NavItemDashboard path={item.path} />
                        </NavLi>
                    ) : (
                        <NavLi key={item.title} sx={{ mt: 1.5 }}>
                            <NavItem
                                subItem
                                title={item.title}
                                path={item.path}
                                active={item.path === removeLastSlash(pathname)}
                            />
                        </NavLi>
                    ),
                )}
            </NavUl>
        </Stack>
    );
}
