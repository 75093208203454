export const navSectionClasses = {
    mini: {
        root: 'nav__section__mini',
    },
    horizontal: {
        root: 'nav__section__horizontal',
    },
    vertical: {
        root: 'nav__section__vertical',
    },
    item: {
        root: 'mnl__nav__item',
        icon: 'mnl__nav__item__icon',
        info: 'mnl__nav__item__info',
        texts: 'mnl__nav__item__texts',
        title: 'mnl__nav__item__title',
        arrow: 'mnl__nav__item__arrow',
        caption: 'mnl__nav__item__caption',
    },
    li: 'mnl__nav__li',
    ul: 'mnl__nav__ul',
    paper: 'mnl__nav__paper',
    subheader: 'mnl__nav__subheader',
    state: {
        open: 'state--open',
        active: 'state--active',
        disabled: 'state--disabled',
    },
};
