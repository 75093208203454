import type { FabProps } from '@mui/material/Fab';
import Fab from '@mui/material/Fab';

import { useMotionValueEvent, useScroll } from 'framer-motion';
import { useState } from 'react';

import { Iconify } from '../../iconify/Iconify';

export type BackToTopProps = FabProps & {
    value?: number;
};

/**
 * @param param {@link BackToTopProps}
 */
export function BackToTop({ value = 90, sx, ...other }: BackToTopProps) {
    const { scrollYProgress } = useScroll();

    const [show, setShow] = useState<boolean>(false);

    // eslint-disable-next-line require-jsdoc
    const backToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    useMotionValueEvent(scrollYProgress, 'change', (latest) => {
        const isEnd = Math.floor(latest * 100) > value; // unit is %
        setShow(isEnd);
    });

    return (
        <Fab
            aria-label="Back to top"
            onClick={backToTop}
            sx={{
                width: 48,
                height: 48,
                position: 'fixed',
                transform: 'scale(0)',
                right: { xs: 24, md: 32 },
                bottom: { xs: 24, md: 32 },
                zIndex: (theme) => theme.zIndex.speedDial,
                transition: (theme) => theme.transitions.create(['transform']),
                ...(show && { transform: 'scale(1)' }),
                ...sx,
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...other}
        >
            <Iconify width={24} icon="solar:double-alt-arrow-up-bold-duotone" />
        </Fab>
    );
}
