import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export type Validation = {
    id: number;
    /**
     * Name of the IFC file
     */
    ifc_file: string;
    /**
     * Name of the IFC file
     */
    ids_file: string;
    result: {
        /**
         * Relative path to the output file
         */
        output: string;
        /**
         * Status of the validation
         */
        status: boolean;
        /**
         * Name of the BCF file that was created
         */
        bcf_file_name: string;
    };
    bcf_result_file: string;
};

export const validationAPI = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/v1/bim/',
    }),
    tagTypes: ['Validation'],
    endpoints: (builder) => ({
        getValidation: builder.query<Validation, number>({
            query: (id) => `validation/${id}`,
        }),
    }),
});

export const { useGetValidationQuery } = validationAPI;
