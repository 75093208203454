import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import type { Breakpoint, SxProps, Theme } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';

import { Link as RouterLink } from '@tanstack/react-router';

import { Logo } from '../../components/logo/Logo';

const LINKS = [
    {
        headline: 'Tikab',
        children: [
            { name: 'About us', href: '/about' },
            { name: 'Contact us', href: '/contact' },
            { name: 'FAQs', href: '/fac' },
        ],
    },
    {
        headline: 'Legal',
        children: [
            { name: 'Terms and condition', href: '#' },
            { name: 'Privacy policy', href: '#' },
        ],
    },
    { headline: 'Contact', children: [{ name: 'support@tikab.com', href: '#' }] },
];

export type FooterProps = {
    layoutQuery: Breakpoint;
    sx?: SxProps<Theme>;
};

/**
 * The `Footer` component renders the footer section of the layout.
 * It includes a logo, a description, and a list of links organized in a grid layout.
 */
export function Footer({ layoutQuery, sx }: FooterProps) {
    const theme = useTheme();

    return (
        <Box component="footer" sx={{ position: 'relative', bgcolor: 'background.default', ...sx }}>
            <Divider />

            <Container
                sx={{
                    pb: 5,
                    pt: 10,
                    textAlign: 'center',
                    [theme.breakpoints.up(layoutQuery)]: { textAlign: 'unset' },
                }}
            >
                <Logo />

                <Grid
                    container
                    sx={{
                        mt: 3,
                        justifyContent: 'center',
                        [theme.breakpoints.up(layoutQuery)]: { justifyContent: 'space-between' },
                    }}
                >
                    <Grid {...{ xs: 12, [layoutQuery]: 3 }}>
                        <Typography
                            variant="body2"
                            sx={{
                                mx: 'auto',
                                maxWidth: 280,
                                [theme.breakpoints.up(layoutQuery)]: { mx: 'unset' },
                            }}
                        >
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Architecto
                            quam repudiandae cupiditate harum animi omnis, voluptatem corrupti
                            quidem, labore, nihil quibusdam in culpa inventore doloremque expedita
                            impedit dignissimos. Placeat, itaque?
                        </Typography>
                    </Grid>

                    <Grid {...{ xs: 12, [layoutQuery]: 6 }}>
                        <Stack
                            spacing={5}
                            sx={{
                                flexDirection: 'column',
                                [theme.breakpoints.up(layoutQuery)]: { flexDirection: 'row' },
                            }}
                        >
                            {LINKS.map((list) => (
                                <Stack
                                    key={list.headline}
                                    spacing={2}
                                    sx={{
                                        width: 1,
                                        alignItems: 'center',
                                        [theme.breakpoints.up(layoutQuery)]: {
                                            alignItems: 'flex-start',
                                        },
                                    }}
                                >
                                    <Typography component="div" variant="overline">
                                        {list.headline}
                                    </Typography>

                                    {list.children.map((link) => (
                                        <Link
                                            key={link.name}
                                            component={RouterLink}
                                            href={link.href}
                                            color="inherit"
                                            variant="body2"
                                        >
                                            {link.name}
                                        </Link>
                                    ))}
                                </Stack>
                            ))}
                        </Stack>
                    </Grid>
                </Grid>

                <Typography variant="body2" sx={{ mt: 10 }}>
                    © All rights reserved.
                </Typography>
            </Container>
        </Box>
    );
}

export type HomeFooterProps = {
    sx?: SxProps<Theme>;
};

/**
 * HomeFooter component renders the footer section of the home page.
 */
export function HomeFooter({ sx }: HomeFooterProps) {
    return (
        <Box
            component="footer"
            sx={{
                py: 5,
                textAlign: 'center',
                position: 'relative',
                bgcolor: 'background.default',
                ...sx,
            }}
        >
            <Container>
                <Logo />
                <Box sx={{ mt: 1, typography: 'caption' }}>
                    © All rights reserved.
                    <br /> made by
                    <Link href="https://tikab.com/"> Tikab </Link>
                </Box>
            </Container>
        </Box>
    );
}
