import Box from '@mui/material/Box';
import type { StackProps } from '@mui/material/Stack';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type { SxProps, Theme } from '@mui/material/styles';

import { varFade } from '@mej/ui-next/src/components/animate/variants/fade';
import { textGradient } from '@mej/ui-next/src/theme/styles/mixins';
import { varAlpha } from '@mej/ui-next/src/theme/styles/utils';
import type { MotionProps } from 'framer-motion';
import { m } from 'framer-motion';

type TextProps = {
    sx?: SxProps<Theme>;
    title: React.ReactNode;
    variants?: MotionProps['variants'];
};

type Props = StackProps & {
    txtGradient?: string;
    title: React.ReactNode;
    caption?: React.ReactNode;
    description?: React.ReactNode;
    slotProps?: {
        title?: Omit<TextProps, 'title'>;
        caption?: Omit<TextProps, 'title'>;
        description?: Omit<TextProps, 'title'>;
    };
};

/**
 * A functional component that renders a section caption with animation and styling.
 */
export function SectionCaption({ title, variants, sx }: TextProps) {
    return (
        <Stack
            component={m.span}
            variants={variants ?? varFade({ distance: 24 }).inUp}
            sx={{ typography: 'overline', color: 'text.disabled', ...sx }}
        >
            {title}
        </Stack>
    );
}

/**
 * Component for rendering a section title with optional caption, gradient text, and description.
 */
export function SectionTitle({
    title,
    caption,
    slotProps,
    txtGradient,
    description,
    ...other
}: Props) {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Stack spacing={3} {...other}>
            {caption && (
                <SectionCaption
                    title={caption}
                    variants={slotProps?.caption?.variants}
                    sx={slotProps?.caption?.sx}
                />
            )}

            <Typography
                component={m.h2}
                variant="h2"
                variants={slotProps?.title?.variants ?? varFade({ distance: 24 }).inUp}
                sx={slotProps?.title?.sx}
            >
                {`${title} `}
                <Box
                    component="span"
                    sx={(theme) => ({
                        opacity: 0.4,
                        display: 'inline-block',
                        ...textGradient(
                            `to right, ${theme.vars.palette.text.primary}, ${varAlpha(theme.vars.palette.text.primaryChannel, 0.2)}`,
                        ),
                    })}
                >
                    {txtGradient}
                </Box>
            </Typography>

            {description && (
                <Typography
                    component={m.p}
                    variants={slotProps?.description?.variants ?? varFade({ distance: 24 }).inUp}
                    sx={{ color: 'text.secondary', ...slotProps?.description?.sx }}
                >
                    {description}
                </Typography>
            )}
        </Stack>
    );
}
