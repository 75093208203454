import { createFileRoute } from '@tanstack/react-router';

import { MainLayout } from '../layouts/main/layout';
import { HomePage } from '../pages/HomePage';

// eslint-disable-next-line require-jsdoc
function Index() {
    return (
        <MainLayout data={{ nav: [] }}>
            <HomePage />
        </MainLayout>
    );
}

export const Route = createFileRoute('/')({
    component: Index,
});
