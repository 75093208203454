import { AppProvider } from '@mej/ui-next';
import { STORAGE_KEY, defaultSettings } from '@mej/ui-next/src/components/settings/config-settings';
import type { SettingsState } from '@mej/ui-next/src/components/settings/types';
import { schemeConfig } from '@mej/ui-next/src/theme/color-scheme-script';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { Provider } from 'react-redux';

import { routeTree } from '../routeTree.gen';
import './App.css';
import { store } from './app/store';

STORAGE_KEY.key = 'simsala-settings';
schemeConfig.modeStorageKey = 'simsala-theme-mode';

export const settings: SettingsState = {
    ...defaultSettings,
    colorScheme: 'dark',
} as const;

const router = createRouter({
    routeTree,
    defaultPreload: false,
    context: {
        user: undefined!,
        login: undefined!,
        logout: undefined!,
    },
});

declare module '@tanstack/react-router' {
    interface Register {
        router: typeof router;
    }
}

/**
 * The main application component that sets up the application context and layout.
 *
 * @returns {JSX.Element} The rendered application component.
 *
 * @component
 * @example
 * return (
 *   <App />
 * )
 *
 * @remarks
 * This component uses the `AppProvider` to provide application-wide settings and context.
 * It also includes a `CssBaseline` component to apply a consistent baseline CSS.
 * The main layout is structured using `Stack` components from Material-UI, with responsive
 * direction and spacing adjustments.
 *
 * The background styling includes a radial gradient that changes based on the theme mode.
 */
export function App() {
    return (
        <Provider store={store}>
            <AppProvider settings={settings}>
                <RouterProvider router={router} />
            </AppProvider>
        </Provider>
    );
}
