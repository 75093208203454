import type { AppBarProps } from '@mui/material/AppBar';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import type { ContainerProps } from '@mui/material/Container';
import Container from '@mui/material/Container';
import type { ToolbarProps } from '@mui/material/Toolbar';
import Toolbar from '@mui/material/Toolbar';
import type { Breakpoint } from '@mui/material/styles';
import { styled, useTheme } from '@mui/material/styles';

import { useScrollOffSetTop } from '../../hooks/use-scroll-offset-top';
import { bgBlur } from '../../theme/styles/mixins';
import { varAlpha } from '../../theme/styles/utils';
import { layoutClasses } from '../classes';

const StyledElevation = styled('span')(({ theme }) => ({
    left: 0,
    right: 0,
    bottom: 0,
    m: 'auto',
    height: 24,
    zIndex: -1,
    opacity: 0.48,
    borderRadius: '50%',
    position: 'absolute',
    width: `calc(100% - 48px)`,
    boxShadow: theme.customShadows.z8,
}));

export type HeaderSectionProps = AppBarProps & {
    layoutQuery: Breakpoint;
    disableOffset?: boolean;
    disableElevation?: boolean;
    slots?: {
        leftArea?: React.ReactNode;
        rightArea?: React.ReactNode;
        topArea?: React.ReactNode;
        centerArea?: React.ReactNode;
        bottomArea?: React.ReactNode;
    };
    slotProps?: {
        toolbar?: ToolbarProps;
        container?: ContainerProps;
    };
};

/**
 * The `HeaderSection` component renders a customizable header section for the layout.
 * It includes areas for top, left, center, right, and bottom slots, and supports various styles and configurations.
 *
 */
export function HeaderSection({
    sx,
    slots,
    slotProps,
    disableOffset,
    disableElevation,
    layoutQuery = 'md',
    ...other
}: HeaderSectionProps) {
    const theme = useTheme();

    const { offsetTop } = useScrollOffSetTop();

    const toolbarStyles = {
        default: {
            minHeight: 'auto',
            height: 'var(--layout-header-mobile-height)',
            transition: theme.transitions.create(['height', 'background-color'], {
                easing: theme.transitions.easing.easeInOut,
                duration: theme.transitions.duration.shorter,
            }),
            [theme.breakpoints.up('sm')]: {
                minHeight: 'auto',
            },
            [theme.breakpoints.up(layoutQuery)]: {
                height: 'var(--layout-header-desktop-height)',
            },
        },
        offset: {
            ...bgBlur({ color: varAlpha(theme.vars.palette.background.defaultChannel, 0.8) }),
        },
    };

    return (
        <AppBar
            position="sticky"
            className={layoutClasses.header}
            sx={{
                zIndex: 'var(--layout-header-zIndex)',
                ...sx,
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...other}
        >
            {slots?.topArea}

            <Toolbar
                disableGutters
                sx={{
                    ...toolbarStyles.default,
                    ...(!disableOffset && offsetTop && toolbarStyles.offset),
                    ...slotProps?.toolbar?.sx,
                }}
            >
                <Container
                    sx={{
                        height: 1,
                        display: 'flex',
                        alignItems: 'center',
                        ...slotProps?.container?.sx,
                    }}
                >
                    {slots?.leftArea}

                    <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'center' }}>
                        {slots?.centerArea}
                    </Box>

                    {slots?.rightArea}
                </Container>
            </Toolbar>

            {slots?.bottomArea}

            {!disableElevation && offsetTop && <StyledElevation />}
        </AppBar>
    );
}
