import { Box } from '@mui/material';
import type { BoxProps } from '@mui/material/Box';
import NoSsr from '@mui/material/NoSsr';

import { forwardRef } from 'react';

import { logoClasses } from './classes';

//

export type LogoProps = BoxProps & {
    href?: string;
    disableLink?: boolean;
};

export const Logo = forwardRef<HTMLDivElement, LogoProps>(
    (
        { width = 40, height = 40, disableLink = false, className, href = '/', sx, ...other },
        ref,
    ) => {
        // const theme = useTheme();

        // const gradientId = useId();

        // const PRIMARY_LIGHT = theme.vars.palette.primary.light;

        // const PRIMARY_MAIN = theme.vars.palette.primary.main;

        // const PRIMARY_DARK = theme.vars.palette.primary.dark;

        /*
         * OR using local (public folder)
         * const logo = ( <Box alt="logo" component="img" src={`${CONFIG.site.basePath}/logo/logo-single.svg`} width={width} height={height} /> );
         */
        const logo = (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                viewBox="0 0 512 512"
            />
        );

        return (
            <NoSsr
                fallback={
                    <Box
                        width={width}
                        height={height}
                        className={logoClasses.root.concat(className ? ` ${className}` : '')}
                        sx={{
                            flexShrink: 0,
                            display: 'inline-flex',
                            verticalAlign: 'middle',
                            ...sx,
                        }}
                    />
                }
            >
                <Box
                    component="a"
                    ref={ref}
                    href={href}
                    width={width}
                    height={height}
                    className={logoClasses.root.concat(className ? ` ${className}` : '')}
                    aria-label="logo"
                    sx={{
                        flexShrink: 0,
                        display: 'inline-flex',
                        verticalAlign: 'middle',
                        ...(disableLink && { pointerEvents: 'none' }),
                        ...sx,
                    }}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...other}
                >
                    {logo}
                </Box>
            </NoSsr>
        );
    },
);
