import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

import designIcon from '@mej/ui-next/public/assets/icons/home/ic-design.svg';
import developmentIcon from '@mej/ui-next/public/assets/icons/home/ic-development.svg';
import brandIcon from '@mej/ui-next/public/assets/icons/home/ic-make-brand.svg';
import { MotionViewport } from '@mej/ui-next/src/components/animate/motion-viewport';
import { varFade } from '@mej/ui-next/src/components/animate/variants/fade';
import { SvgColor } from '@mej/ui-next/src/components/svg-color/SvgColor';
import { stylesMode, varAlpha } from '@mej/ui-next/src/theme/styles/utils';
import { m } from 'framer-motion';

import { SectionTitle } from './components/section-title';
import { CircleSvg, FloatLine, FloatPlusIcon } from './components/svg-elements';
import chartImage from './home-chart.webp';

const brandIconUrl = new URL(brandIcon, import.meta.url).href;
const designIconUrl = new URL(designIcon, import.meta.url).href;
const developmentIconUrl = new URL(developmentIcon, import.meta.url).href;
const chartImageUrl = new URL(chartImage, import.meta.url).href;

const ITEMS = [
    {
        icon: brandIconUrl,
        title: 'Enkelt',
        description: 'Ett knapptryck sen sköter appen resten.',
    },
    {
        icon: designIconUrl,
        title: 'Snabbt och enkelt',
        description: 'Få feedback på ditt arbete och förbättra det snabbt.',
    },
    {
        icon: developmentIconUrl,
        title: 'Kraftfullt',
        description: 'Kan köras både i molnet och on-premises och det finns API för integration.',
    },
];

/**
 * The `HomeSimsalaBIM` component renders a section of the home page with visual elements,
 * including floating icons, lines, a section title, a description list, and an image.

 * ```
 */
export function HomeSimsalaBIM({ sx, ...other }: BoxProps) {
    const renderLines = (
        <>
            <FloatPlusIcon sx={{ top: 72, left: 72 }} />
            <FloatPlusIcon sx={{ bottom: 72, left: 72 }} />
            <FloatLine sx={{ top: 80, left: 0 }} />
            <FloatLine sx={{ bottom: 80, left: 0 }} />
            <FloatLine vertical sx={{ top: 0, left: 80 }} />
        </>
    );

    const renderDescription = (
        <>
            <SectionTitle
                caption="Föverkligar möjligheter"
                title="Hur funkar det?"
                // txtGradient="SimsalaBIM?"
                sx={{ mb: { xs: 5, md: 8 }, textAlign: { xs: 'center', md: 'left' } }}
            />

            <Stack
                spacing={6}
                sx={{
                    maxWidth: { sm: 560, md: 400 },
                    mx: { xs: 'auto', md: 'unset' },
                }}
            >
                {ITEMS.map((item) => (
                    <Box
                        component={m.div}
                        key={item.title}
                        variants={varFade({ distance: 24 }).inUp}
                        gap={3}
                        display="flex"
                    >
                        <SvgColor src={item.icon} sx={{ width: 40, height: 40 }} />
                        <Stack spacing={1}>
                            <Typography variant="h5" component="h6">
                                {item.title}
                            </Typography>
                            <Typography sx={{ color: 'text.secondary' }}>
                                {item.description}
                            </Typography>
                        </Stack>
                    </Box>
                ))}
            </Stack>
        </>
    );

    const renderImg = (
        <Stack
            component={m.div}
            variants={varFade({ distance: 24 }).inRight}
            alignItems="center"
            justifyContent="center"
            sx={{ height: 1, position: 'relative' }}
        >
            <Box
                sx={{
                    left: 0,
                    width: 720,
                    borderRadius: 2,
                    position: 'absolute',
                    bgcolor: 'background.default',
                    boxShadow: (theme) =>
                        `-40px 40px 80px 0px ${varAlpha(theme.vars.palette.grey['500Channel'], 0.16)}`,
                    [stylesMode.dark]: {
                        boxShadow: (theme) =>
                            `-40px 40px 80px 0px ${varAlpha(theme.vars.palette.common.blackChannel, 0.16)}`,
                    },
                }}
            >
                <Box component="img" alt="Home Chart" src={chartImageUrl} sx={{ width: 720 }} />
            </Box>
        </Stack>
    );

    return (
        <Box
            component="section"
            sx={{
                overflow: 'hidden',
                position: 'relative',
                py: { xs: 10, md: 20 },
                ...sx,
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...other}
        >
            <MotionViewport>
                {renderLines}

                <Container sx={{ position: 'relative' }}>
                    <Grid
                        container
                        columnSpacing={{ xs: 0, md: 8 }}
                        sx={{ position: 'relative', zIndex: 9 }}
                    >
                        <Grid xs={12} md={6} lg={7}>
                            {renderDescription}
                        </Grid>

                        <Grid md={6} lg={5} sx={{ display: { xs: 'none', md: 'block' } }}>
                            {renderImg}
                        </Grid>
                    </Grid>

                    <CircleSvg
                        variants={varFade().in}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                    />
                </Container>
            </MotionViewport>
        </Box>
    );
}
