import { Box } from '@mui/material';
import type { BoxProps } from '@mui/material/Box';

import type { MotionProps } from 'framer-motion';
import { m } from 'framer-motion';
import { forwardRef } from 'react';

import { varContainer } from './variants/container';

export type MotionContainerProps = BoxProps &
    MotionProps & {
        animate?: boolean;
        action?: boolean;
    };

export const MotionContainer = forwardRef<HTMLDivElement, MotionContainerProps>(
    ({ animate, action = false, children, ...other }, ref) => {
        const commonProps = {
            ref,
            component: m.div,
            variants: varContainer(),
            initial: action ? false : 'initial',
            // eslint-disable-next-line no-nested-ternary
            animate: action ? (animate ? 'animate' : 'exit') : 'animate',
            exit: action ? undefined : 'exit',
            ...other,
        };

        // eslint-disable-next-line react/jsx-props-no-spreading
        return <Box {...commonProps}>{children}</Box>;
    },
);
