import { HomeView } from '@mej/ui-next/src/sections/home/view/HomeView';
import { Helmet } from 'react-helmet-async';

import { HomeHero } from '../sections/home/home-hero';
import { HomeSimsalaBIM } from '../sections/home/home-simsalabim';

// import { HomeView } from 'src/sections/home/view';

export const metadata = {
    title: 'SimsalaBIM',
    description:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla quam velit, vulputate eu pharetra nec, mattis ac neque.',
};

// eslint-disable-next-line require-jsdoc
export function HomePage() {
    return (
        <>
            <Helmet>
                <title> {metadata.title}</title>
                <meta name="description" content={metadata.description} />
            </Helmet>
            <HomeView hero={<HomeHero />}>
                <HomeSimsalaBIM />
            </HomeView>
        </>
    );
}
