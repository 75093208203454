import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { useDispatch, useSelector } from 'react-redux';

import uploadReducer from '../features/upload/uploadSlice';
import { validationAPI } from './api/bim';

export const store = configureStore({
    reducer: {
        upload: uploadReducer,
        [validationAPI.reducerPath]: validationAPI.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(validationAPI.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
