import type { BoxProps } from '@mui/material/Box';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { MotionContainer } from '@mej/ui-next/src/components/animate/motion-container';
import { varFade } from '@mej/ui-next/src/components/animate/variants/fade';
import { Iconify } from '@mej/ui-next/src/components/iconify/Iconify';
import { useResponsive } from '@mej/ui-next/src/hooks/use-responsive';
import { textGradient } from '@mej/ui-next/src/theme/styles/mixins';
import type { MotionValue } from 'framer-motion';
import { m, useMotionValueEvent, useScroll, useSpring, useTransform } from 'framer-motion';
import { useRef, useState } from 'react';

import { HeroBackground } from './components/hero-background';

const smKey = 'sm' as const;
const mdKey = 'md' as const;
const lgKey = 'lg' as const;

/**
 * A functional component that renders an animated div using the `Box` component.
 * The animation is defined by the `varFade` function with a distance of 24 and an upward direction.
 */
function AnimatedDiv({ children, component = m.div }: BoxProps & { children: React.ReactNode }) {
    return (
        <Box component={component} variants={varFade({ distance: 24 }).inUp}>
            {children}
        </Box>
    );
}

/**
 * Custom hook that creates a spring animation for a given motion value.
 */
function useTransformY(value: MotionValue<number>, distance: number) {
    const physics = {
        mass: 0.1,
        damping: 20,
        stiffness: 300,
        restDelta: 0.001,
    };

    return useSpring(useTransform(value, [0, 1], [0, distance]), physics);
}

/**
 * Custom hook that calculates the scroll percentage of an element.
 */
function useScrollPercent() {
    const elementRef = useRef<HTMLDivElement>(null);

    const { scrollY } = useScroll();

    const [percent, setPercent] = useState(0);

    useMotionValueEvent(scrollY, 'change', (scrollHeight) => {
        let heroHeight = 0;

        if (elementRef.current) {
            heroHeight = elementRef.current.offsetHeight;
        }

        const scrollPercent = Math.floor((scrollHeight / heroHeight) * 100);

        if (scrollPercent >= 100) {
            setPercent(100);
        } else {
            setPercent(Math.floor(scrollPercent));
        }
    });

    return { elementRef, percent, scrollY };
}

/**
 * HomeHero component renders the hero section of the home page with animated heading, text, and buttons.
 * It utilizes motion animations and responsive design to create a dynamic and engaging user experience.
 *
 * @example
 * <HomeHero sx={{ backgroundColor: 'primary.main' }} />
 */
export function HomeHero({ sx, ...other }: BoxProps) {
    const theme = useTheme();

    const scroll = useScrollPercent();

    const mdUp = useResponsive('up', mdKey);

    const distance = mdUp ? scroll.percent : 0;

    const y1 = useTransformY(scroll.scrollY, distance * -7);
    const y2 = useTransformY(scroll.scrollY, distance * -6);
    const y3 = useTransformY(scroll.scrollY, distance * -4);

    const opacity: MotionValue<number> = useTransform(
        scroll.scrollY,
        [0, 1],
        [1, mdUp ? Number((1 - scroll.percent / 100).toFixed(1)) : 1],
    );

    const renderHeading = (
        <AnimatedDiv>
            <Box
                component="h1"
                display="flex"
                flexWrap="wrap"
                justifyContent="center"
                sx={{
                    ...theme.typography.h2,
                    my: 0,
                    mx: 'auto',
                    maxWidth: 680,
                    fontFamily: theme.typography.fontSecondaryFamily,
                    [theme.breakpoints.up(lgKey)]: { fontSize: 72, lineHeight: '90px' },
                }}
            >
                <Box component="span" sx={{ width: 1 }}>
                    Ta ditt projekt till nya höjder med
                </Box>
                <Box
                    component={m.span}
                    animate={{ backgroundPosition: '200% center' }}
                    transition={{
                        duration: 20,
                        ease: 'linear',
                        repeat: Infinity,
                        repeatType: 'reverse',
                    }}
                    sx={{
                        ...textGradient(
                            `300deg, ${theme.vars.palette.primary.main} 0%, ${theme.vars.palette.warning.main} 25%, ${theme.vars.palette.primary.main} 50%, ${theme.vars.palette.warning.main} 75%, ${theme.vars.palette.primary.main} 100%`,
                        ),
                        backgroundSize: '400%',
                    }}
                >
                    SimsalaBIM
                </Box>
            </Box>
        </AnimatedDiv>
    );

    const renderText = (
        <AnimatedDiv>
            <Typography
                variant="body2"
                sx={{
                    mx: 'auto',
                    [theme.breakpoints.up(smKey)]: { whiteSpace: 'pre' },
                    [theme.breakpoints.up(lgKey)]: { fontSize: 20, lineHeight: '36px' },
                }}
            >
                {`Ta tillbaka kontrollen över dina BIM-modeller och validera innehållet automatiskt. \nFå BCF-rapporter och följ upp ändringar i modellen.`}
            </Typography>
        </AnimatedDiv>
    );

    const renderButtons = (
        <Box display="flex" flexWrap="wrap" justifyContent="center" gap={{ xs: 1.5, sm: 2 }}>
            <AnimatedDiv>
                <Stack alignItems="center" spacing={2.5}>
                    <Button
                        href="/dashboard/"
                        color="inherit"
                        size="large"
                        variant="contained"
                        startIcon={<Iconify width={24} icon="iconoir:flash" />}
                    >
                        <span>
                            Kom igång
                            <Box
                                component="small"
                                sx={{
                                    mt: '-3px',
                                    opacity: 0.64,
                                    display: 'flex',
                                    fontSize: theme.typography.pxToRem(10),
                                    fontWeight: theme.typography.fontWeightMedium,
                                }}
                            >
                                v0.0.1
                            </Box>
                        </span>
                    </Button>

                    <Link
                        color="inherit"
                        variant="body2"
                        target="_blank"
                        rel="noopener"
                        href="https://www.tikab.com"
                        underline="always"
                        sx={{ gap: 0.5, alignItems: 'center', display: 'inline-flex' }}
                    >
                        Inte en kund?
                        <Iconify width={16} icon="eva:external-link-fill" />
                    </Link>
                </Stack>
            </AnimatedDiv>
        </Box>
    );

    return (
        <Box
            ref={scroll.elementRef}
            component="section"
            sx={{
                overflow: 'hidden',
                position: 'relative',
                [theme.breakpoints.up(mdKey)]: {
                    minHeight: 760,
                    height: '100vh',
                    maxHeight: 1440,
                    display: 'block',
                    willChange: 'opacity',
                    mt: 'calc(var(--layout-header-desktop-height) * -1)',
                },
                ...sx,
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...other}
        >
            <Box
                component={m.div}
                style={{ opacity }}
                sx={{
                    width: 1,
                    display: 'flex',
                    position: 'relative',
                    flexDirection: 'column',
                    transition: theme.transitions.create(['opacity']),
                    [theme.breakpoints.up(mdKey)]: {
                        height: 1,
                        position: 'fixed',
                        maxHeight: 'inherit',
                    },
                }}
            >
                <Container
                    component={MotionContainer}
                    sx={{
                        py: 3,
                        gap: 5,
                        zIndex: 9,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        [theme.breakpoints.up(mdKey)]: {
                            flex: '1 1 auto',
                            justifyContent: 'center',
                            py: 'var(--layout-header-desktop-height)',
                        },
                    }}
                >
                    <Stack spacing={3} sx={{ textAlign: 'center' }}>
                        <m.div style={{ y: y1 }}>{renderHeading}</m.div>
                        <m.div style={{ y: y2 }}>{renderText}</m.div>
                    </Stack>
                    <m.div style={{ y: y3 }}>{renderButtons}</m.div>
                </Container>

                <HeroBackground />
            </Box>
        </Box>
    );
}
