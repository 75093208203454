import { useMotionValueEvent, useScroll } from 'framer-motion';
import { useCallback, useMemo, useRef, useState } from 'react';

/**
 * Use scroll offset top hook to get the scroll offset top.
 */
export type UseScrollOffSetTopReturn = {
    offsetTop: boolean;
    elementRef: React.RefObject<HTMLDivElement>;
};

/**
 * Use scroll offset top hook to get the scroll offset top.
 *
 * Usage:
 *
 * ```tsx
 * 1: Applies to top <header/>
 * const { offsetTop } = useScrollOffSetTop(80);
 *
 * Or
 *
 * 2: Applies to element
 * const { offsetTop, elementRef } = useScrollOffSetTop(80);
 * <div ref={elementRef} />
 * ```
 *
 * @param top
 * @returns A {@link UseScrollOffSetTopReturn} object with the offsetTop and elementRef
 */
export function useScrollOffSetTop(top = 0): UseScrollOffSetTopReturn {
    const elementRef = useRef<HTMLDivElement>(null);

    const { scrollY } = useScroll();

    const [offsetTop, setOffsetTop] = useState(false);

    const handleScrollChange = useCallback(
        (val: number) => {
            const scrollHeight = Math.round(val);

            if (elementRef?.current) {
                const rect = elementRef.current.getBoundingClientRect();
                const elementTop = Math.round(rect.top);

                setOffsetTop(elementTop < top);
            } else {
                setOffsetTop(scrollHeight > top);
            }
        },
        [elementRef, top],
    );

    useMotionValueEvent(
        scrollY,
        'change',
        useMemo(() => handleScrollChange, [handleScrollChange]),
    );

    const memoizedValue = useMemo(() => ({ elementRef, offsetTop }), [offsetTop]);

    return memoizedValue;
}
