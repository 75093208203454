/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export const STEP = {
    IDLE: 'IDLE',
    UPLOADING: 'UPLOADING',
    UPLOADED: 'UPLOADED',
    ERROR: 'ERROR',
} as const;

export type UploadStep = (typeof STEP)[keyof typeof STEP];

/**
 * False is not uploaded.
 * True is uploaded.
 */
export type FilesToUpload = Record<string, boolean>;

export interface UploadState {
    step: UploadStep;
    filsToUpload: FilesToUpload;
}

const initialState: UploadState = {
    step: STEP.IDLE,
    filsToUpload: {},
};

export const uploadSlice = createSlice({
    name: 'upload',
    initialState,
    reducers: {
        setUploadStep: (state, action: PayloadAction<UploadStep>) => {
            state.step = action.payload;
        },
        resetFilesToUpload: (state) => {
            state.filsToUpload = {};
        },
        setFilesToUpload: (state, action: PayloadAction<Record<string, boolean>>) => {
            state.filsToUpload = action.payload;
        },
        updateFileToUpload: (
            state: UploadState,
            action: PayloadAction<{ file: string; isUploaded: boolean }>,
        ) => {
            // check if file is already in the list
            if (state.filsToUpload[action.payload.file] === undefined) {
                throw new Error("File doesn't exist in the list");
            }
            state.filsToUpload[action.payload.file] = action.payload.isUploaded;

            // Check if all files are uploaded
            const allFilesUploaded = Object.values(state.filsToUpload).every(
                (isUploaded) => isUploaded,
            );

            if (allFilesUploaded) {
                // state.step = STEP.UPLOADED;
                state.filsToUpload = {};
            }
        },
    },
});

export const { setUploadStep, resetFilesToUpload, setFilesToUpload, updateFileToUpload } =
    uploadSlice.actions;

export default uploadSlice.reducer;
