import Stack from '@mui/material/Stack';

import { NavUl } from '../../../../components/nav-section';
import type { NavMainProps } from '../types';
import { NavList } from './nav-desktop-list';

/**
 * NavDesktop component renders a navigation bar for desktop view.
 */
export function NavDesktop({ data, sx }: NavMainProps) {
    return (
        <Stack component="nav" sx={{ height: 1, ...sx }}>
            <NavUl
                sx={{
                    gap: 5,
                    height: 1,
                    flexDirection: 'row',
                    alignItems: 'center',
                }}
            >
                {data.map((list) => (
                    <NavList key={list.title} data={list} />
                ))}
            </NavUl>
        </Stack>
    );
}
