import { hasParams, isExternalLink, removeLastSlash, removeParams } from '../utils';
import { usePathname } from './use-pathname';

/**
 * Determines if a given item path is active based on the current pathname.
 *
 * @param itemPath - The path of the item to check.
 * @param deep - Optional. If true, performs a deep check including child paths and parameters. Defaults to true.
 * @returns A boolean indicating whether the item path is active.
 *
 * The function performs the following checks:
 * 1. If the item path starts with '#' or is an external link, it returns false.
 * 2. If `deep` is true or the item path has parameters:
 *    - Returns true if the current pathname includes the item path.
 *    - Returns true if the item path has parameters and the base path matches the current pathname.
 * 3. If `deep` is false:
 *    - Returns true if the current pathname exactly matches the item path.
 *
 * Example usage:
 * ```
 * const isActive = useActiveLink('/dashboard/user', true);
 * ```
 */
export function useActiveLink(itemPath: string, deep: boolean = true): boolean {
    const pathname = removeLastSlash(usePathname());

    const pathHasParams = hasParams(itemPath);

    /* Start check */
    const notValid = itemPath.startsWith('#') || isExternalLink(itemPath);

    if (notValid) {
        return false;
    }
    /* End check */

    /**
     * [1] Apply for Item has children or has params.
     */
    const isDeep = deep || pathHasParams;

    // console.info(isDeep ? '[deep]   :' : '[normal] :', itemPath, '-?-', pathname);

    if (isDeep) {
        /**
         * [1] Deep: default
         * @itemPath 			 = '/dashboard/user'
         * @match pathname = '/dashboard/user'
         * @match pathname = '/dashboard/user/list'
         * @match pathname = '/dashboard/user/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b15/edit'
         */
        const defaultActive = pathname.includes(itemPath);

        /**
         * [1] Deep: has params
         * @itemPath 			 = '/dashboard/test?id=e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1'
         * @match pathname = '/dashboard/test'
         */

        const originItemPath = removeParams(itemPath);

        const hasParamsActive = pathHasParams && originItemPath === pathname;

        return defaultActive || hasParamsActive;
    }

    /**
     * [1] Normal: active
     * @itemPath 			 = '/dashboard/calendar'
     * @match pathname = '/dashboard/calendar'
     */
    return pathname === itemPath;
}
