import { useLocation } from '@tanstack/react-router';
import { useMemo } from 'react';

/**
 * Get the current pathname.
 */
export function usePathname() {
    const { pathname } = useLocation();

    return useMemo(() => pathname, [pathname]);
}
